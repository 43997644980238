@font-face {
    font-family: 'Press Start 2P';
    src:
}

body {
    font-family: 'Press Start 2P', cursive;
}

ol,
ul {
    padding-left: 30px;
}

.board-row:after {
    clear: both;
    content: "";
    display: table;
}

.status {
    margin-bottom: 10px;
}

.square {
    background: #fff;
    border: 4px solid black;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    padding: 0;
    text-align: center;
    width: 42px;
    height: 42px;
    border-radius: 4px;
}

.square:focus {
    outline: none;
}

.kbd-navigation .square:focus {
    background: #ddd;
}

.game {
    display: flex;
    flex-align: center;
    flex-direction: row;
}


.standard-red {
    background-color: #DE1D1D;
}

.standard-white {
    background-color: #fff;
}

.standard-yellow {
    background-color: #EAD422;
}

.standard-blue {
    background-color: #0073b7;
}

.standard-orange {
    background-color: #F3940D;
}

.standard-green {
    background-color: #16a085;
}

.celestial-red {
    border-color: #b71c1c;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #b71c1c;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.celestial-white {
    border-color: #e8e8e8;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #e0e0e0;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.celestial-yellow {
    border-color: #EAD422;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #EAD422;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.celestial-blue {
    border-color: #0d47a1;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #0d47a1;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.celestial-orange {
    border-color: #F3940D;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #F3940D;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.celestial-green {
    border-color: #00695c;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #00695c;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.carrot-red {
    border-color: #FF5722;
    background: repeating-linear-gradient(45deg, #FF5722, #FF5722 6px, #FF7043 6px, #FF7043 12px);
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.carrot-white {
    border-color: #A1887F;
    background: repeating-linear-gradient(45deg, #A1887F, #A1887F 6px, #BCAAA4 6px, #BCAAA4 12px);
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.carrot-yellow {
    border-color: #e6e6fa;
    background: repeating-linear-gradient(45deg, #e6e6fa, #e6e6fa 6px, #e6e6fa 6px, #e6e6fa 12px);
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.carrot-blue {
    border-color: #7B1FA2;
    background: repeating-linear-gradient(45deg, #7B1FA2, #7B1FA2 6px, #9C27B0 6px, #9C27B0 12px);
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.carrot-orange {
    border-color: #FFA000;
    background: repeating-linear-gradient(45deg, #FFA000, #FFA000 6px, #FFB300 6px, #FFB300 12px);
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.carrot-green {
    border-color: #4CAF50;
    background: repeating-linear-gradient(45deg, #4CAF50, #4CAF50 6px, #66BB6A 6px, #66BB6A 12px);
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.ethereal-green {
    border-color: #4caf50;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #4caf50;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}
    
.ethereal-blue {
    border-color: #9e9e9e;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #9e9e9e;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}
    
.ethereal-red {
    border-color: #795548;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #795548;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}
    
.ethereal-yellow {
    border-color: #8bc34a;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #8bc34a;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}
    
.ethereal-orange {
    border-color: #616161;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #616161;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}
    
.ethereal-white {
    border-color: #a1887f;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #a1887f;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
}

.vintage-red {
    border-color: #ff5252;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #ff5252;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.3);
}
    
.vintage-white {
    border-color: #ffffff;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #ffffff;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.3);
}
    
.vintage-yellow {
    border-color: #ffeb3b;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #ffeb3b;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.3);
}
    
.vintage-blue {
    border-color: #448aff;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #448aff;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.3);
}
    
.vintage-orange {
    border-color: #ff9800;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #ff9800;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.3);
}
    
.vintage-green {
    border-color: #4caf50;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #4caf50;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.3);
}

.mythic-red {
    border-color: #8b0000;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #8b0000;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4), 0 0 10px rgba(255, 0, 0, 0.5);
}

.mythic-white {
    border-color: #c0c0c0;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #c0c0c0;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4), 0 0 10px rgba(255, 255, 255, 0.5);
}

.mythic-yellow {
    border-color: #FFD700;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #FFD700;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4), 0 0 10px rgba(255, 215, 0, 0.5);
}

.mythic-blue {
    border-color: #000080;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #000080;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4), 0 0 10px rgba(0, 0, 255, 0.5);
}

.mythic-orange {
    border-color: #FF4500;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #FF4500;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4), 0 0 10px rgba(255, 69, 0, 0.5);
}

.mythic-green {
    border-color: #228B22;
    background: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0.15)), #228B22;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4), 0 0 10px rgba(34, 139, 34, 0.5);
}

.temporal-red {
    border-color: #5e31b5;
    background: linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #5e31b5;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4);
}
    
.temporal-white {
    border-color: #392a6b;
    background: linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #392a6b;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4);
}
    
.temporal-yellow {
    border-color: #6848b7;
    background: linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #6848b7;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4);
}
    
.temporal-blue {
    border-color: #1f1f3d;
    background: linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #1f1f3d;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4);
}
    
.temporal-orange {
    border-color: #3c2d84;
    background: linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #3c2d84;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4);
}
    
.temporal-green {
    border-color: #52307c;
    background: linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 75%, rgba(255,255,255,0.1) 75%, rgba(255,255,255,0.1)), #52307c;
    box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.4);
}

.penryn-text{
    font-size: 2px;
}


.penryn-red {
    background-color: #DE1D1D;
}

.penryn-white {
    background-color: #fff;
}

.penryn-yellow {
    background-color: #EAD422;
}

.penryn-blue {
    background-color: #0073b7;
}

.penryn-orange {
    background-color: #F3940D;
}

.penryn-green {
    background-color: #16a085;
}

.columns {
    display: flex;
    flex-direction: row;
    columns: 2
}

.cube {
    display: inline-block;
}

.cube-container {}

#main {
    margin: 0;
    padding: 0;
    background-color: white;
    margin: 10px auto;
}

.btn-group-wraper {
    position: absolute;
    top: 30px;
    left: 65px;
}

#cube-container {
    width: 100%;
    max-width: 100%;
    -webkit-perspective: 800px;
    -moz-perspective: 800px;
    perspective: 800px;
    position: relative;
    overflow: hidden;
}


#shape {
    position: relative;
    margin: 0 auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    margin-bottom: 60px;
    width: 128px;
    height: 128px;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(-5deg) rotateY(-30deg);
    -moz-transform: rotateX(-5deg) rotateY(-30deg);
    transform: rotateX(-5deg) rotateY(-30deg);
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    transition: transform 2s;
}

@-webkit-keyframes spin {

    /*from { -webkit-transform: rotateX(0) rotateY(0); }*/
    to {
        -webkit-transform: rotateX(360deg) rotateY(360deg);
    }
}

@-moz-keyframes spin {

    /*from { -moz-transform: rotateX(0) rotateY(0); }*/
    to {
        -moz-transform: rotateX(360deg) rotateY(360deg);
    }
}

@keyframes spin {

    /*from { transform: rotateX(0) rotateY(0); }*/
    to {
        transform: rotateX(360deg) rotateY(360deg);
    }
}

#shape>div {
    position: absolute;
    width: 128px;
    height: 128px;
    background-color: #e8e8e8;
    border-radius: 4px;
    border: 1px inset #e8e8e8;
}

#shape a {
    color: black;
    text-decoration: none;
    text-align: center;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    display: block;
    font-size: 50px;
}

#shape .ft {
    -webkit-transform: translateZ(63px);
    -moz-transform: translateZ(63px);
    transform: translateZ(63px);
}

#shape .rt {
    -webkit-transform: rotateY(90deg) translateZ(63px);
    -moz-transform: rotateY(90deg) translateZ(63px);
    transform: rotateY(90deg) translateZ(63px);
}

#shape .bk {
    -webkit-transform: rotateY(180deg) translateZ(63px);
    -moz-transform: rotateY(180deg) translateZ(63px);
    transform: rotateY(180deg) translateZ(63px);
}

#shape .lt {
    -webkit-transform: rotateY(270deg) translateZ(63px);
    -moz-transform: rotateY(270deg) translateZ(63px);
    transform: rotateY(270deg) translateZ(63px);
}

#shape .tp {
    -webkit-transform: rotateX(90deg) translateZ(63px);
    -moz-transform: rotateX(90deg) translateZ(63px);
    transform: rotateX(90deg) translateZ(63px);
}

#shape .bm {
    -webkit-transform: rotateX(270deg) rotateY(0deg) translateZ(63px);
    -moz-transform: rotateX(270deg) rotateY(0deg) translateZ(63px);
    transform: rotateX(270deg) rotateY(0deg) translateZ(63px);
}

/*transitions*/
#shape.show-ft {
    -webkit-transform: translateZ(63px);
    -moz-transform: translateZ(63px);
    transform: translateZ(63px);
}

#shape.show-rt {
    -webkit-transform: rotateX(360deg) rotateY(-90deg);
    -moz-transform: rotateX(360deg) rotateY(-90deg);
    transform: rotateX(360deg) rotateY(-90deg);
}

#shape.show-bk {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

#shape.show-lt {
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
}

#shape.show-tp {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: rotateX(-90deg) rotateY(-360deg);
    -moz-transform: rotateX(-90deg) rotateY(-360deg);
    transform: rotateX(-90deg) rotateY(-360deg);
}

#shape.show-bm {
    -webkit-transform: rotateX(90deg) rotateY(360deg);
    -moz-transform: rotateX(90deg) rotateY(360deg);
    transform: rotateX(90deg) rotateY(360deg);
}

/*zoom in*/
#shape.zi {
    -webkit-transform: scale(1.5, 1.5);
    -moz-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
}

/*animations*/
#shape.spin {
    -webkit-animation: spin 15s infinite linear;
    -moz-animation: spin 15s infinite linear;
    animation: spin 15s infinite linear;
}

#shape {
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    transition: all 1s;
    -webkit-transform: scale(1);
    -webkit-transform: rotateX(-15deg) rotateY(-30deg);
    -moz-transform: scale(1);
    -moz-transform: rotateX(-15deg) rotateY(-30deg);
    transform: scale(1);
    transform: rotateX(-15deg) rotateY(-30deg);
    text-align: center;
}

.xflip- {
    transform: rotateX(-15deg) rotateY(-210deg) !important;
}

.yflip {
    transform: rotateX(-195deg) rotateY(30deg) !important;
}

.xflip-yflip {
    transform: rotateX(-195deg) rotateY(210deg) !important;
}

.control-panel {
    padding: 20px;
}

.turns-panel {
    margin-top: 20px;
    max-width: 100%;
    overflow-wrap: anywhere;
}

.text-right {
    text-align: right;
}

.btn-block {
    width: 100%;
}

.turns-container {
    margin-top: 20px;
    max-width: 100%;
    overflow-wrap: anywhere;
}

.left-column-container {
    max-width: 100%;
    float: left;
}

.right-column-container {
    max-width: 100%;
    float: right;
}

.pbtn {
    text-align: center;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    margin-left: 50%;
    margin-top: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    padding: 10px;
    color: #eee;
    transition: box-shadow 0.1s, background 0.1s, padding 0.1s;
    margin-top: 20px;
    margin-bottom: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.pbtn:active {
    width: calc(100% + 3px);
    padding-top: 15px;
    padding-bottom: 5px;
    transition: box-shadow 0.1s, background 0.1s, padding 0.1s;
}

.pbtn-primary {
    box-shadow: 5px 0px #133DB9, -5px 0px #133DB9, 0px 5px #133DB9, 0px -5px #133DB9, 0px 15px #2979FF, -5px 10px #2A6CDA, 5px 10px #2A6CDA;
    background: #133DB9;
}

.pbtn-primary:active {
    box-shadow: 5px 5px #2353E0, -5px 5px #2353E0, 0px 10px #2353E0, 0px 0px #133DB9, 0px 15px #2979FF, -5px 10px #2A6CDA, 5px 10px #2A6CDA;
    background: #2353E0;
}

.pbtn-secondary {
    box-shadow: 5px 0px #545353, -5px 0px #545353, 0px 5px #545353, 0px -5px #545353, 0px 15px #A9A9A9, -5px 10px #E3E3E3, 5px 10px #E3E3E3;
    background: #545353;
}

.pbtn-secondary:active {
    box-shadow: 5px 0px #808080, -5px 0px #808080, 0px 5px #808080, 0px -5px #808080, 0px 15px #A9A9A9, -5px 10px #E3E3E3, 5px 10px #E3E3E3;
    background: #808080;
}

.btn-limiter {
    width: 400px;
}

.btn-limiter:active {
    width: calc(400px + 3px);
}

.btn-auto {
    width: auto !important;
}

.pin-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}