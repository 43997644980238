a {
    text-decoration: none;
}

.rarity-common {
    color: grey;
}

.rarity-rare {
    color: blue;
}

.rarity-legendary{
    color: #ff8c00;
}