.whitelist-text{
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: inline-block;
    background-image: linear-gradient(to left, purple, blue, green, #fada5e, red);
    -webkit-background-clip: text;
    color: transparent;
    border: 2px solid;
    border-image-slice: 1;
    margin-top: 20px;
    padding: 10px;
}

